$transitionSpeed: .3s;

$colorBlue: #5199FF;
$colorBlueHover: #005F95;
$colorBlack: #333;
$colorRed: #E30613;
$colorRedHover: #CC061A;
$colorLightGrey: #F2F2F2;
$colorLightGreyHover: #E6E6E6;
$colorGrey: #7F817F;
$colorGreyText: #9D9D9D;
$colorOrange: #F08C00;
$colorOrangeHover: #DE8100;
$colorYellow: #FFD600;