.ab-product-card{
  width: 321px;
  height: 361px;
  text-decoration: none;
  color: currentColor;
  font-family: "Corpid";
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  transition: $transitionSpeed;
  display: block;
  @media screen and (max-width: 380px) {
    width: 100%;
    height: auto;
  }
  &:hover{
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.08);
    border-color: transparent;
    .ab-product-card__button._compare{
      opacity: 1;
      visibility: visible;
    }
  }
  &__tags{
    display: flex;
    margin-bottom: 12px;
  }
  &__tag{
    padding: 3px 6px;
    box-sizing: border-box;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    border-radius: 2px;
    &._blue{
      background-color: $colorBlue;
    }
    &:not(:first-child){
      margin-left: 16px;
    }
  }
  &__name{
    transition: $transitionSpeed;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    &._active{
      overflow: visible;
    }
  }
  &__title{
    font-size: 16px;
    line-height: 20px;
    color: $colorBlack;
    transition: $transitionSpeed;
    padding-right: 16px;
    box-sizing: border-box;
    &:hover{
      color: $colorRed;
    }
  }
  &__alt{
    position: absolute;
    background-color: $colorGrey;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    padding: 3px 6px;
    box-sizing: border-box;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visiblity;
    transition-duration: $transitionSpeed;
    &._active{
      opacity: 1;
      visibility: visible
    }
  }
  &__details{
    display: flex;
    &-left{
      margin-right: 20px;
    }
  }
  &__image{
    width: 120px;
    height: 144px;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    img{
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }
  }
  &__colors{
    display: flex;
    justify-content: center;
    align-items: center;
    &-item{
      margin: 0 4px;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      &:last-child{
        margin-right: 0;
      }
      &:first-child{
        margin-left: 0;
      }
      &._blue{
        background-color: $colorBlue;
      }
      &._yellow{
        background-color: $colorYellow;
      }
      &._red{
        background-color: $colorRed;
      }
    }
  }
  &__info{
    margin-bottom: 24px;
    &-row{
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      font-weight: 700;
      &:not(:last-child){
        margin-bottom: 12px;
      }
      &-name{
        color: $colorBlack;
      }
      &-value{
        color: $colorRed;
      }
    }
  }
  &__features{
    font-size: 14px;
    line-height: 16px;
    color: $colorGreyText;
    margin-bottom: 12px;
  }
  &__reviews{
    display: flex;
    align-items: center;
    &-icon{
      border-radius: 50%;
      padding: 3px 6px;
      box-sizing: border-box;
      color: #fff;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $colorOrange;
      margin-right: 8px;
    }
    &-name{
      font-size: 14px;
      line-height: 14px;
      color: $colorGreyText;
    }
  }
  &__details{
    margin-bottom: 20px;
    display: flex;
  }
  &__bottom{
    display: flex;
    justify-content: space-between;
  }
  &__buttons{
    padding-top: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  &__price{
    &-item{
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;
      color: $colorBlack;
      &._old{
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: $colorGrey;
        text-decoration: line-through;
        margin-bottom: 4px;
      }
    }
  }
  &__button{
    padding: 10px;
    display: block;
    box-sizing: border-box;
    border: none;
    height: 40px;
    border-radius: 4px;
    color: #fff;
    transition: $transitionSpeed;
    &:not(:first-child){
      margin-left: 8px;
    }
    &._red{
      background-color: $colorRed;
      &:hover{
        background-color: $colorRedHover;
      }
    }
    &._orange{
      background-color: $colorOrange;
      &:hover{
        background-color: $colorOrangeHover;
      }
    }
    &._blue{
      background-color: $colorBlue;
      &:hover{
        background-color: $colorBlueHover;
      }
    }
    &._grey{
      background-color: $colorLightGrey;
      &:hover{
        background-color: $colorLightGreyHover;
      }
    }
    &._short{
      box-sizing: border-box;
      width: 40px;
    }
    &._full{
      width: 92px;
      font-size: 16px;
      line-height: 20px;
    }
    &._icon{
      &-red{
        .ab-icon{
          fill: $colorRed;
        }
      }
      &-grey{
        .ab-icon{
          fill: $colorBlack;
        }
      }
    }
    &._compare{
      opacity: 0;
      visibility: hidden;
    }
  }
  &__unavailable{
    width: 138px;
    color: $colorGreyText;
    font-size: 16px;
    line-height: 20px;
  }
}

.ab-ellipsis{
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
  &-line{
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 100%;
    position: relative;
    overflow: hidden;
  }
}