@import 'variables';
@import 'mixins';
@import 'vendors';
@import 'fonts';

body{
  font-size: 16px;
  box-sizing: border-box;
}

p{
  margin: 0;
  padding: 0;
}



@import "/home/gitlab-runner/builds/83acd65b/0/mts/optimize/mts-optimize/src/pages/index.scss";
