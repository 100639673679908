@font-face {
  font-family: "Corpid";
  src: url("../fonts/CorpidE1SCd_Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Corpid";
  src: url("../fonts/CorpidE1SCd_Light.woff2") format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Corpid";
  src: url("../fonts/CorpidE1SCd_Bold.woff2") format("woff2");
  font-weight: 700;
}